import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {

    loadTemplatePack : async function() {
        let data = await axios.get(NetworkUtils.HOST + '/template-item-pack', config);
        if (data.data.length > 0) {
            return data.data[0].templatePack;
        } else {
            return  null;
        }
    },
}